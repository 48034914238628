import React from 'react'
import { Head } from '@components'
import * as st from '@assets/styl/terms.module.styl'
import { translatePath } from '../translations'
import { useLanguage } from '../hooks'

const Terms = () => {
  const { t } = useLanguage()

  return (
    <>
      <Head pathname={translatePath('/termos-de-uso/')} title={t('Terms.title')} description={t('Terms.descriptionPage')}/>
      
      <section className={st.core}>
        <div>
          <h1>Termos de uso</h1>
          <p>1. Disposições iniciais e termos gerais</p>
          <p>
            O presente documento rege o relacionamento do site com os usuários,
            que de algum modo acessam ou interagem com o Dimas Empreendimentos,
            por meio de dispositivos móveis ou qualquer outra tecnologia hábil.
          </p>
          <p>
            Os termos presentes neste instrumento são destinados a todos os
            usuários e devem sempre ser interpretados em conjunto com a Política
            de Privacidade do site Dimas Empreendimentos.
          </p>
          <p>
            Destacamos que todas as disputas ou reivindicações decorrentes da
            relação entre o Dimas e o Usuário, ainda que não contratuais, serão
            regidas pela legislação brasileira.
          </p>
          <p>
            Recomendamos que todos os usuários dediquem um momento para leitura
            deste instrumento antes de se cadastrarem ou fornecerem seus dados
            pessoais no nosso site.
          </p>
          <p>2. Regras de utilização do site</p>
          <p>
            Para acessar o nosso site e utilizar alguma das ferramentas
            disponíveis para nos contatar e fazer login na Área do Cliente, o
            usuário/titular deve:
          </p>
          <ol>
            <li>a) Ser maior de 18 anos e possuir capacidade civil;</li>
            <li>
              b) Garantir que toda a informação transmitida no registro e em
              todos os outros momentos é verdadeira, precisa, atual e completa;
            </li>
            <li>
              c) Aceitar que todas as informações enviadas por si ao Dimas
              Empreendimentos serão armazenadas em banco de dados;
            </li>
            <li>
              d) No caso da conta destinada ao acesso da “Área do Cliente”, ser
              responsável pelo sigilo, exclusividade e segurança de sua senha de
              acesso. O próprio Usuário é o único autorizado a acessar a própria
              na plataforma e é responsável por qualquer atividade que ocorra em
              sua conta;
            </li>
            <li>
              e) Não utilizar o site para propósitos ilegais ou outros que não
              sejam autorizados pela legislação brasileira;
            </li>
            <li>
              f) Concordar que é o único responsável pela própria conduta e por
              qualquer informação, material, arquivo, texto, link e qualquer
              outro conteúdo ou material que inserir no nosso site;
            </li>
            <li>
              g) Concordar em não fornecer nenhum dado pessoal que não lhe tenha
              sido solicitado e não seja imprescindível para a finalidade do
              tratamento de dados realizado pelo Dimas;
            </li>
            <li>
              h) Concordar em não enviar à nossa empresa conteúdo de cunho
              difamatório, calunioso, injurioso, violento, obsceno,
              pornográfico, ilegal ou de qualquer modo ofensivo, seja ou não
              esse material protegido por lei.
            </li>
          </ol>
          <p>3. Uso das ferramentas para contato</p>
          <p>
            O acesso e a utilização do nosso site são oferecidos ao usuário
            unicamente para o seu uso pessoal.
          </p>
          <p>
            Sendo assim, o usuário declara expressamente que apenas acessará ou
            utilizará o site do Dimas Empreendimentos para os propósitos a que
            ele se destina e que está ciente da responsabilização civil e
            criminal pelo fornecimento de dados falsos ou de terceiros, sendo
            passível por danos diretos ou indiretos causados ao Dimas ou a
            terceiros que tenham acesso.
          </p>
          <p>Entre outros fatores, o usuário concorda que não poderá:</p>
          <ul>
            <li>
              Enviar ou transmitir quaisquer informações que possam induzir
              outros a erro, incitem a violência ou intolerância de qualquer
              natureza; ofensivas ou que contrariem a lei e/ou a ordem pública;
            </li>
            <li>
              Efetuar cadastro ou fornecer dados em um dos campos destinados ao
              contato com o cliente utilizando-se de informações falsas ou de
              propriedade de terceiros;
            </li>
            <li>
              Enviar ou transmitir informações de terceiros, ou de qualquer
              forma alterar, remover ou corromper informações de terceiros;
            </li>
            <li>
              Enviar ou transmitir quaisquer formas de códigos maliciosos,
              vírus eletrônicos ou conteúdo destrutivo que possam vir a expor
              informações do Dimas Empreendimentos e de terceiros;
            </li>
            <li>Danificar, desabilitar, sobrecarregar, prejudicar o nosso site.</li>
          </ul>
          <p>4. Violação aos termos de uso</p>
          <p>
            A infração a qualquer das proibições contidas nestes Termos de Uso
            acarretará na imediata exclusão do titular de nossa base de dados,
            com a consequente interrupção do contato e eventuais negociações.
          </p>
          <p>
            As violações também podem sujeitar o Usuário à responsabilização
            cível e/ou penal cabível.
          </p>
          <p>
            Ademais, nos reservamos ao direito de, mesmo sem aviso prévio,
            monitorar, editar, bloquear ou mesmo remover qualquer conteúdo ou
            usuário que possa estar em desacordo com a sistemática dos presentes
            Termos de Uso ou com a legislação brasileira.
          </p>
          <p>5. Foro Aplicável</p>
          <p>
            Os presentes Termos de Uso consistem no acordo total e pleno entre
            Dimas Empreendimentos e o Usuário e substituem qualquer acordo
            prévio, oral ou escrito feito entre ambos. Fica eleito o foro da
            comarca de São José/SC para a resolução de eventuais conflitos.
          </p>
        </div>
      </section>
    </>
  )
}

export default Terms
